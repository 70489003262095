import React from 'react';
import './App.css';

import Landing from './Landing.js';

function App() {
  return (
    <Landing />
  );
}

export default App;
